import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Logo from "@/src/images/2020/img_logo_horizontal.svg"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <h2 className={props.className}>
    <Logo />
  </h2>
)
// ______________________________________________________
//
const spStyle = `
  width: 160px;
  margin: 0 0 24px;
`
const StyledComponent = styled(Component)`
  width: 266px;
  margin-right: 98px;
  ${media.lessThan("medium")`${spStyle}`}
  svg {
    width: 100%;
    height: auto;
  }
`
// ______________________________________________________
//
export default StyledComponent
