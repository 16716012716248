import React from "react"
import styled from "styled-components"
import LanguageToggle from "@components/common/languageToggle"
import { Link } from "gatsby"
import media from "styled-media-query"
import ImgLogo from "@/src/images/2020/img_logo_horizontal.svg"

// ______________________________________________________
//
type Props = {
  className?: string
  path?: string
  lang: ReturnType<typeof useToggleLanguage>["lang"]
  toggleLanguage: () => void
}

// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <header className={props.className}>
    <div className="Inner">
      {props.path === "/" ? (
        <>
          <h2 className="Logo">
            <a href="#top">
              <ImgLogo />
            </a>
          </h2>
          <nav className="Nav">
            <ul>
              <li>
                <a href="#top">Top</a>
              </li>
              <li>
                <a href="#schedule">Schedule</a>
              </li>
              <li>
                <a href="#speakers">Speakers</a>
              </li>
              <li>
                <a href="#venue">Venue</a>
              </li>
              {/* <li>
            <a href="#spon">Sponsors</a>
          </li> */}
              {/* <li>
            <a href="#team">Team</a>
          </li> */}
            </ul>
            {/* <LanguageToggle onClick={props.toggleLanguage} lang={props.lang} /> */}
          </nav>
        </>
      ) : (
        <>
          <h2 className="Logo">
            <Link to="/2020/#top">
              <ImgLogo />
            </Link>
          </h2>
          <nav className="Nav">
            <ul>
              <li>
                <Link to="/2020/#top">Top</Link>
              </li>
              <li>
                <Link to="/2020/#schedule">Schedule</Link>
              </li>
              <li>
                <Link to="/2020/#speakers">Speakers</Link>
              </li>
              <li>
                <Link to="/2020/#venue">Venue</Link>
              </li>
              {/* <li>
            <a href="#spon">Sponsors</a>
          </li> */}
              {/* <li>
            <a href="#team">Team</a>
          </li> */}
            </ul>
            {/* <LanguageToggle onClick={props.toggleLanguage} lang={props.lang} /> */}
          </nav>
        </>
      )}
    </div>
  </header>
)

// ______________________________________________________
//
const spStyle = `
  display: none;
`
const StyledComponent = styled(Component)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 64px;
  z-index: 100;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.24);
    opacity: 0.72;
  }
  .Inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 40px 0 48px;
    z-index: 1;
  }
  .Logo {
    width: 134px;
    svg {
      width: 100%;
      height: auto;
    }
    a {
      transition: opacity 0.3s ease-out;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .Nav {
    display: flex;
    align-items: center;
    ul {
      display: flex;
      align-items: center;
      padding-right: 20px;
    }
    li {
      a {
        display: block;
        padding: 12px 24px;
        color: ${({ theme }) => theme.colors.ivory};
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.02em;
        text-decoration: none;
        transition: opacity 0.3s ease-out;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
`

// ______________________________________________________
//
export default StyledComponent
