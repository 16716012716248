import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Item, { NavListItem } from "./item"
// ______________________________________________________
//
export type NavList = {
  navListItems: NavListItem[]
}
type Props = {
  className?: string
} & NavList
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <nav className={props.className}>
    <ul className="Items">
      {props.navListItems.map((item, index) => (
        <Item key={index} {...item} />
      ))}
    </ul>
  </nav>
)
// ______________________________________________________
//
const spStyle = `
  .Items {
    margin: 0;
  }
`
const StyledComponent = styled(Component)`
  .Items {
    margin-right: 12px;
  }
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
