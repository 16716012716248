import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Nav from "./nav"
import Organizer from "./organizer"
import { NavList } from "./nav/list"
// ______________________________________________________
//
type Props = {
  className?: string
  lists: NavList[]
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <footer className={props.className}>
    <div className="Inner">
      <Organizer className="Organizer" />
      <Nav lists={props.lists} />
    </div>
    <div className="Gradient" />
    <small className="Copylight">©2020 React Japan User Group</small>
  </footer>
)
// ______________________________________________________
//
const spStyle = `
  .Inner {
    display: block;
    padding: 48px 32px 62px;
  }
  .Gradient {
    height: 12px;
    background: linear-gradient(
      180deg,
      #282c34 0%,
      #d33736 46.35%,
      #dd6041 60.94%,
      #ffe564 100%
    );
  }
  .Copylight {
    padding: 24px 0;
  }
`
const StyledComponent = styled(Component)`
  background: linear-gradient(180deg, #1c1a19 0%, #282c34 86.04%);
  .Inner {
    display: flex;
    max-width: ${1024 + 80}px;
    margin: 0 auto;
    padding: 104px 40px 120px;
    box-sizing: border-box;
  }
  .Gradient {
    height: 16px;
    background: linear-gradient(
      180deg,
      #282c34 0%,
      #d33736 46.35%,
      #dd6041 60.94%,
      #ffe564 100%
    );
  }
  .Copylight {
    display: block;
    padding: 30px 0;
    background: #282c34;
    color: ${({ theme }) => theme.colors.ivory};
    font-family: "Questrial", sans-serif;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0.02em;
    text-align: center;
  }
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
