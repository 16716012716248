import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import List, { NavList } from "./list"
// ______________________________________________________
//
type Props = {
  className?: string
  lists: NavList[]
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    {props.lists.map((list, index) => (
      <List key={index} navListItems={list.navListItems} />
    ))}
  </div>
)
// ______________________________________________________
//
const spStyle = `
  display: block;
  > *:not(:last-child) {
    margin-bottom: 24px;
  }
`
const StyledComponent = styled(Component)`
  display: flex;
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
