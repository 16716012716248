import React from "react"
import { Link } from "gatsby"
import media from "styled-media-query"
import styled from "styled-components"
// ______________________________________________________
//
export type NavListItem = {
  label: string
  href: string
  target?: "_blank"
  anchor?: true
}
type Props = {
  className?: string
} & NavListItem
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <li className={props.className}>
    {props.target && (
      <a href={props.href} target={props.target} rel="noopener noreferrer">
        {props.label}
      </a>
    )}
    {!props.target && props.anchor && <a href={props.href}>{props.label}</a>}
    {!props.target && !props.anchor && (
      <Link to={props.href}>{props.label}</Link>
    )}
  </li>
)
// ______________________________________________________
//
const spStyle = `
a {
  padding: 12px 0;
  &:hover {
    opacity: 1;
  }
}
`
const StyledComponent = styled(Component)`
  a {
    display: block;
    padding: 12px 24px;
    font-family: "Montserrat", "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.02em;
    color: ${({ theme }) => theme.colors.ivory};
    text-decoration: none;
    transition: opacity ease-out 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
